import { useAlertsStore } from "@/stores/alertsStore";

const useShowAlert = (
  type,
  message,
  hide = false,
  linkText = "",
  href = "#",
) => {
  const alertsStore = useAlertsStore();
  alertsStore.setAlert(type, message, linkText, href);
  if (hide) {
    alertsStore.clearAlert();
  }
};

export default useShowAlert;
